import React from "react";

function HomePage() {
  return (
    <>
      <h1>Break Free!</h1>
      <div className="d-flex justify-content-center">
        <iframe
          title="Break Free teaser"
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FYesterdaysNewsBand%2Fvideos%2F238637615083618%2F&show_text=false&width=560&t=0"
          width="560"
          height="314"
          style={{
            border: "none",
            overflow: "hidden",
          }}
          scrolling="no"
          frameBorder="0"
          allowFullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          allowFullScreen={true}
        ></iframe>
      </div>
      <h3 className="text-center mt-1">Out January 14th!</h3>
    </>
  );
}

export default HomePage;
