import React from "react";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <section className="bg-dark p-2 fixed-bottom">
      <a
        className="btn btn-outline-light btn-floating m-1"
        href="https://www.facebook.com/YesterdaysNewsBand"
        rel="noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>

      <a
        className="btn btn-outline-light btn-floating m-1"
        href="https://www.instagram.com/yesterdaysnewspunk/"
        rel="noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      {/* TODO: move this to the right */}
      <a
        className="btn btn-outline-light btn-floating m-1"
        href="mailto:info@yesterdays-news.com"
        rel="noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </section>
  );
}

export default Footer;
