import React from "react";
import StreamingServices from "./StreamingServices";

function MediaPage() {
  return (
    <>
      <h1>Media</h1>
      <iframe
        title="spotify"
        src="https://open.spotify.com/embed/artist/4jzm0KrN8hVUkmpa19R67O?utm_source=generator"
        width="100%"
        height="400"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      ></iframe>
      <StreamingServices />
    </>
  );
}

export default MediaPage;
