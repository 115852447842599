import React from "react";

function ContactPage() {
  return (
    <>
      <h1>Contact</h1>
      <p></p>
    </>
  );
}

export default ContactPage;
