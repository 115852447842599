import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faDeezer } from "@fortawesome/free-brands-svg-icons";
import { faItunesNote } from "@fortawesome/free-brands-svg-icons";

function StreamingServices() {
  return (
    <>
      <h2 className="text-center">
        Choose your favourite streaming service below!
      </h2>
      <div className="d-flex justify-content-center ">
        <a
          className="btn btn-outline-light btn-floating m-1"
          href="https://www.youtube.com/channel/UC3zW7V-7XBMZD86uue_YZ6g"
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon
            className="streaming-icon"
            icon={faYoutube}
            fixedWidth
          />
        </a>
        <a
          className="btn btn-outline-light btn-floating m-1"
          href="https://open.spotify.com/artist/4jzm0KrN8hVUkmpa19R67O?si=A21ceXj1SvOj3ASiPFDWzQ"
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faSpotify}
            className="streaming-icon"
            fixedWidth
          />
        </a>
        <a
          className="btn btn-outline-light btn-floating m-1"
          href="https://www.deezer.com/en/artist/4951302"
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faDeezer}
            className="streaming-icon"
            fixedWidth
          />
        </a>
        <a
          className="btn btn-outline-light btn-floating m-1"
          href="https://music.apple.com/it/artist/yesterdays-news/1486882775?l=en"
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faItunesNote}
            className="streaming-icon"
            fixedWidth
          />
        </a>
      </div>
    </>
  );
}

export default StreamingServices;
