import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import header from "../../img/yesterdays-news-header2.jpg";

function Header() {
  return (
    <Navbar bg="dark" variant="dark" sticky="top">
      <Link to="">
        <img src={header} alt="Header" width="1920" height="122" />
      </Link>
      <Container>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            {/* <Nav.Link as={Link} to="">
              Home
            </Nav.Link> */}
            <Nav.Link as={Link} to="Shows">
              Shows
            </Nav.Link>
            <Nav.Link as={Link} to="Media">
              Media
            </Nav.Link>
            <Nav.Link as={Link} to="About">
              About
            </Nav.Link>
            <Nav.Link
              href="https://www.epicmerchstore.com/collection/artists/yesterdays-news/"
              rel="noreferrer"
              target="_blank"
            >
              Merch
            </Nav.Link>
            <Nav.Link as={Link} to="Contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
