import React from "react";

function ShowsPage() {
  return (
    <>
      <h1>Upcoming Shows</h1>
      <div className="container">
        {/* <ul>
          <li>
            <ul>
              <li>2022-02-19</li>
              <li>Boiler Room</li>
              <li>Tilburg</li>
              <li>https://fb.me/e/1pt8Ggnbj</li>
            </ul>
          </li>
        </ul> */}

        <h1 className="text-light">TBA</h1>
      </div>
      <h1>Previous Shows</h1>
    </>
  );
}

export default ShowsPage;
