import React from "react";

function ErrorPage() {
  return (
    <>
      <h1>We could not find this page</h1>
    </>
  );
}

export default ErrorPage;
