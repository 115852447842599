import React from "react";
import header from "../img/yesterdays-news-header.jpg";

function AboutPage() {
  return (
    <>
      <h1>About Us</h1>
      {/* <img width="500" height="500" style={{ float: "right" }}></img> */}
      <p>
        Founded in 2017, Yesterday's News combines sounds of many different
        genres of punk along with influences from different metal scenes
        resulting in a melodic punk style with catchy melodies and powerful
        vocals.
      </p>
      <h1></h1>

      <div className="col-sm-12 text-center">
        <h2>Influences</h2>
        <ul className="list-unstyled">
          <li>Rise Against</li>
          <li>No Use For A Name</li>
          <li>Pennywise</li>
          <li>NOFX</li>
          <li>Bad Religion</li>
          <li className="fst-italic fw-lighter">And many more</li>
        </ul>
      </div>

      {/* <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FYesterdaysNewsBand%2Fposts%2F1165896353813650&show_text=true&width=500" width="500" height="525" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
    </>
  );
}

export default AboutPage;
