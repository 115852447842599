import React from "react";
import Header from "./common/Header";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import Footer from "./common/Footer";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import ContactPage from "./ContactPage";
import MediaPage from "./MediaPage";
import ShowsPage from "./ShowsPage";

function App() {
  return (
    <>
      <Header />
      <div className="vh-100 d-inline-block w-100 bg">
        <main className="content ">
          <div className="container-fluid col-md-6">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Shows" element={<ShowsPage />} />
              <Route path="/Media" element={<MediaPage />} />
              <Route path="/About" element={<AboutPage />} />
              <Route path="/Contact" element={<ContactPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
}

export default App;
